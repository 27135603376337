<template>
  <div class="w-full h-full flex items-center justify-center">
    <hyperlink-cell
      v-if="isHiperLink"
      :value="value"
      :route="productMetrics"
      param-name="productId"
      :param-value="data.id"
    />
    <text-cell
      v-else-if="isTextCell"
      :value="value"
      :class="{
        'font-bold': isBold,
      }"
    />
    <numeric-cell v-else-if="isNumericCell" :value="value" />
    <percentage-cell v-else-if="isPercentageCell" :value="value" />
    <currency-cell v-else-if="isCurrencyAndEurCell" :value="value" currency="EUR" />
    <currency-cell v-else-if="isCurrencyAndUsdCell" :value="value" currency="USD" />
    <date-cell v-else-if="isDateCell" :value="value" />
    <campaign-manager-status-action-select v-else-if="isSelectCampaignManagerActionCell" :data="data" />
    <campaign-name-cell v-else-if="isSFCampaign" :data="data" />
    <campaign-manager-actions v-else-if="isActionCell" :data="data" />
    <span v-else class="bg-red-200">{{ column }}--{{ value }}</span>
  </div>
</template>

<script>
import NumericCell from '@/components/atoms/AnalyticsTable/NumericCell';
import TextCell from '@/components/atoms/AnalyticsTable/TextCell';
import { COLUMNS, SECONDARY_COLUMNS } from '@/views/private/modules/thirdParty/analytics/config_v2';
import { COLUMNS as COLUMNS_CM } from '@/views/private/modules/thirdParty/campaignManager/config';
import PercentageCell from '@/components/atoms/AnalyticsTable/PercentageCell';
import CurrencyCell from '@/components/atoms/AnalyticsTable/CurrencyCell';
import DateCell from '@/components/atoms/AnalyticsTable/DateCell';
import HyperlinkCell from '@/components/atoms/AnalyticsTable/HyperlinkCell';
import { metrics as productMetrics } from '@/router/private/modules/thirdParty/demand/product/edit';
import CampaignManagerStatusActionSelect from '@/components/molecules/modules/thirdParty/CampaignManagerStatusActionSelect.vue';
import CampaignManagerActions from '@/components/molecules/modules/thirdParty/CampaignManagerActions.vue';
import CampaignNameCell from '@/components/atoms/AnalyticsTable/CampaignNameCell.vue';

export default {
  name: 'AnalyticsCellOrchestrator',
  components: {
    CampaignNameCell,
    CampaignManagerStatusActionSelect,
    CurrencyCell,
    PercentageCell,
    NumericCell,
    TextCell,
    DateCell,
    HyperlinkCell,
    CampaignManagerActions,
  },
  data() {
    return {
      value: null,
      column: null,
      data: null,
      productMetrics,
    };
  },
  computed: {
    isBold() {
      return (
        [
          // to check
          'ag-Grid-AutoColumn',
          COLUMNS.NAME.value,
          COLUMNS.CAMPAIGN_NAME.value,
          COLUMNS.PLATFORM_NAME.value,
        ].some(key => key === this.column) && this.params?.column?.colId === 'breakdown'
      );
    },
    isHiperLink() {
      return [
        // COLUMNS.PLATFORM_NAME.value,
      ].some(key => key === this.column);
    },
    isTextCell() {
      // TODO rethink headers array each type (maybe in Column class)
      return [
        // to check
        'ag-Grid-AutoColumn',
        COLUMNS.PLATFORM_NAME.value,
        COLUMNS.CAMPAIGN_ID.value,
        COLUMNS.PRODUCT_ID.value,
        COLUMNS.ADSERVER_NAME.value,
        // legacy
        COLUMNS.NAME.value,
        COLUMNS.OFFICE.value,
        COLUMNS.OPPORTUNITY_OWNER.value,
        COLUMNS.PRODUCT_NAME.value,
        COLUMNS.CAMPAIGN_NAME.value,
        COLUMNS.ACCOUNT_MANAGER.value,
        COLUMNS.MODEL.value,
        COLUMNS.PRODUCT_FAMILY.value,
        SECONDARY_COLUMNS.BROWSER.value,
        SECONDARY_COLUMNS.COUNTRY.value,
        SECONDARY_COLUMNS.DATE.value,
        SECONDARY_COLUMNS.DEVICE.value,
        SECONDARY_COLUMNS.CAMPAIGN.value,
        SECONDARY_COLUMNS.PRODUCT.value,
        // Config for Campaign Manager
        COLUMNS_CM.TYPE.value,
        COLUMNS_CM.STAGE.value,
        COLUMNS_CM.OWNER.value,
        COLUMNS_CM.ACCOUNT_MANAGER.value,
        COLUMNS_CM.CAMPAIGN_ID.value,
        COLUMNS_CM.PRODUCT_ID.value,
        COLUMNS_CM.OFFICE.value,
        COLUMNS_CM.BOOKED.value,
        COLUMNS_CM.PRICE_EUR.value,
        COLUMNS_CM.PRICE_USD.value,
        COLUMNS_CM.PRIMARY_KPI.value,
        COLUMNS_CM.SECONDARY_KPI.value,
        COLUMNS_CM.GROUP.value,
      ].some(key => key === this.column);
    },
    isNumericCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [
        // to check
        COLUMNS.UU.value,
        COLUMNS.VISITS_BOOKED.value,
        COLUMNS.VISITS.value,
        COLUMNS.LEADS_BOOKED.value,
        COLUMNS.LEADS.value,
        COLUMNS.BOOKED_UNITS.value,
        COLUMNS.DAILY.value,
        COLUMNS.IMPRESSIONS_BOOKED.value,
        COLUMNS.IMPRESSIONS.value,
        COLUMNS.MEASURABLE_IMPRESSIONS.value,
        COLUMNS.VIEWABLE_IMPRESSIONS.value,
        COLUMNS.CLICKS_BOOKED.value,
        COLUMNS.CLICKS.value,
        COLUMNS.VIEWS_BOOKED.value,
        COLUMNS.VIEWS.value,
        COLUMNS.ENGAGEMENTS_BOOKED.value,
        COLUMNS.ENGAGEMENTS.value,
        COLUMNS.SESSIONS.value,
      ].some(key => key === this.column);
    },
    isPercentageCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [
        // legacy
        COLUMNS.PACING.value,
        COLUMNS.VIEWABILITY.value,
        COLUMNS.CTR.value,
        COLUMNS.VTR.value,
        COLUMNS.ENGAGEMENTS_PERCENT.value,
      ].some(key => key === this.column);
    },
    isCurrencyAndEurCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [COLUMNS.NET_REVENUE_EUR.value, COLUMNS.NET_CPM_EUR.value].some(key => key === this.column);
    },
    isCurrencyAndUsdCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [COLUMNS.NET_REVENUE_USD.value, COLUMNS.NET_CPM_USD.value].some(key => key === this.column);
    },
    isDateCell() {
      // TODO rethink headers array in each type (maybe in Column class)
      return [
        // to check
        // legacy
        COLUMNS.DATE_START.value,
        COLUMNS.DATE_END.value,
        // Config for Campaign Manager
        COLUMNS_CM.START.value,
        COLUMNS_CM.END.value,
        COLUMNS_CM.CREATED.value,
      ].some(key => key === this.column);
    },
    isSelectCampaignManagerActionCell() {
      return [
        // Config for Campaign Manager
        COLUMNS_CM.STATUS.value,
      ].some(key => key === this.column);
    },
    isSFCampaign() {
      return [
        // Config for Campaign Manager
        COLUMNS_CM.NAME.value,
      ].some(key => key === this.column);
    },
    isActionCell() {
      return [
        // Config for Campaign Manager
        COLUMNS_CM.ACTIONS.value,
      ].some(key => key === this.column);
    },
  },
  beforeMount() {
    this.column = this.params.column.colId;
    this.data = this.params.data;
    this.value = this.params.value;
  },
};
</script>

<style scoped></style>
