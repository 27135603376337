import { dateList as defaultDateList } from '@/utils/dateTime/dateList.js';
import { addDays } from '@/utils/dateTime/addDays';
import { TODAY } from '@/utils/dateTime/today';
import { BREAKDOWN_VALUES } from '@/model/modules/thirdParty/analytics/BreakdownValues';
import Column from '@/model/shared/Column';

export const textFilterValueGetter = params => {
  const colId = params.column.getColId();
  const value = params.data[colId];
  const valueIsNA = value === 'N/A';
  const parentData = params?.node?.parent?.data;
  const parentHasData = !!parentData && parentData[colId];

  if (valueIsNA && parentHasData) {
    return parentData[colId];
  }

  return params.data[colId];
};
const transformColumnsForAgGrid = column => {
  switch (column.value) {
    case COLUMNS.PACING.value:
      column.cellClassRules = {
        'bg-custom-red': params => params.value < 70 || params.value > 150,
        'bg-custom-yellow': params =>
          (params.value >= 70 && params.value < 95) || (params.value > 130 && params.value <= 150),
        'bg-custom-green': params => params.value >= 95 && params.value <= 130,
      };
      column.filter = false;
      break;
    case COLUMNS.PLATFORM_NAME.value:
      column.type = 'textColumn';
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      break;
    case COLUMNS.NAME.value:
    case COLUMNS.BREAKDOWN.value:
    case COLUMNS.CAMPAIGN_NAME.value:
    case COLUMNS.ADSERVER_NAME.value:
    case COLUMNS.PRODUCT_NAME.value:
      column.width = 50;
      column.minWidth = 50;
      column.pinned = 'left';
      column.type = 'textColumn';
      column.suppressSizeToFit = true;
      column.suppressAutoSize = true;
      column.headerClass = 'main-headers';
      column.suppressMovable = true;
      break;
    case COLUMNS.DATE_START.value:
    case COLUMNS.DATE_END.value:
      column.filter = false;
      column.type = 'dateColumn';
      break;
    case COLUMNS.ID.value:
    case COLUMNS.MODEL.value:
      column.type = 'textColumn';
      column.filter = false;
      break;
    case COLUMNS.VIEWABILITY.value:
    case COLUMNS.VTR.value:
    case COLUMNS.CTR.value:
    case COLUMNS.NET_CPM_EUR.value:
    case COLUMNS.NET_CPM_USD.value:
    case COLUMNS.NET_REVENUE_EUR.value:
    case COLUMNS.NET_REVENUE_USD.value:
      column.filter = false;
      column.type = 'currencyColumn';
      break;
    case COLUMNS.OPPORTUNITY_OWNER.value:
    case COLUMNS.OFFICE.value:
    case COLUMNS.ACCOUNT_MANAGER.value:
    case COLUMNS.PRODUCT_ID.value:
    case COLUMNS.CAMPAIGN_ID.value:
      column.type = 'textColumn';
      break;
    case COLUMNS.KPI.value:
    case COLUMNS.PRODUCT_FAMILY.value:
      column.filter = false;
      column.type = 'textColumn';
      break;
    case SECONDARY_COLUMNS.BROWSER.value:
    case SECONDARY_COLUMNS.COUNTRY.value:
    case SECONDARY_COLUMNS.DATE.value:
    case SECONDARY_COLUMNS.DEVICE.value:
      column.type = 'textColumn';
      break;
    default:
      column.filter = false;
      column.type = 'numberColumn';
      break;
  }

  return { headerName: `${column.visibleTitle()}`, field: column.value, ...column };
};

export const COLUMNS = Object.freeze({
  ID: new Column('ID', 'id').setVisible(false),
  BREAKDOWN: new Column('BREAKDOWN', 'breakdown').setVisible(false),
  ACCOUNT_MANAGER: new Column('ACCOUNT MANAGER', 'accountName'),
  ACTIONS: new Column('ACTIONS', 'actions').setBreakdowns([
    BREAKDOWN_VALUES.product,
    BREAKDOWN_VALUES.productcampaign,
    BREAKDOWN_VALUES.campaign,
  ]),
  KPI: new Column('KPI', 'kpi').toRight(),
  LINE_ITEM_ID: new Column('SF LINE ITEM ID', 'id').setBreakdowns([
    BREAKDOWN_VALUES.product,
    BREAKDOWN_VALUES.productcampaign,
    BREAKDOWN_VALUES.campaign,
  ]),
  NAME: new Column('NAME', 'breakdown').setClass('break-normal'),
  CAMPAIGN_NAME: new Column('CAMPAIGN NAME', 'campaignName')
    .setClass('break-normal')
    .setBreakdowns([BREAKDOWN_VALUES.product, BREAKDOWN_VALUES.platform]),
  PRODUCT_NAME: new Column('PRODUCT NAME', 'productName').setBreakdowns([
    BREAKDOWN_VALUES.campaign,
    BREAKDOWN_VALUES.platform,
  ]),
  ADSERVER_NAME: new Column('ADSERVER NAME', 'adserverName'),
  OFFICE: new Column('OFFICE', 'office'),
  OPPORTUNITY_OWNER: new Column('OPPORTUNITY OWNER', 'oppName'),
  OPPORTUNITY_RECORD_TYPE_NAME: new Column('OPPORTUNITY RECORD TYPE', 'recordTypeName'),
  PACING: new Column('PACING', 'pacing').toRight(),
  MODEL: new Column('MODEL', 'rate'),
  PLATFORM_NAME: new Column('PLATFORM NAME', 'platformName').setBreakdowns([
    BREAKDOWN_VALUES.campaign,
    BREAKDOWN_VALUES.product,
  ]),
  CAMPAIGN_ID: new Column('CAMPAIGN ID', 'campaignId'),
  PRODUCT_ID: new Column('PRODUCT ID', 'productId'),
  BOOKED_UNITS: new Column('BOOKED UNITS', 'bookedUnits').toRight(),
  DAILY: new Column('DAILY', 'daily').toRight(),
  IMPRESSIONS_BOOKED: new Column('IMPRESSIONS BOOKED', 'impressionsBooked').toRight(),
  IMPRESSIONS: new Column('IMPRESSIONS', 'impressions').toRight(),
  MEASURABLE_IMPRESSIONS: new Column('MEASURABLE IMPRESSIONS', 'measurableImpressions').toRight(),
  VIEWABLE_IMPRESSIONS: new Column('VIEWABLE IMPRESSIONS', 'viewableImpressions').toRight(),
  CLICKS: new Column('CLICKS', 'clicks').toRight(),
  CLICKS_BOOKED: new Column('CLICKS BOOKED', 'clicksBooked').toRight(),
  CPM_NET_SALES: new Column('CPM NET SALES', 'CPMNetSales')
    .setQueryKeys(['CPMNetSalesAmount', 'CPMNetSalesCurrency'])
    .toRight(),
  CTR: new Column('CTR', 'ctr').toRight(),
  UU: new Column('UU', 'uniqueUsers').toRight(),
  DATE_END: new Column('PRODUCT END DATE', 'endDate'),
  DATE_START: new Column('PRODUCT START DATE', 'startDate'),
  VIEWABILITY: new Column('VIEWABILITY', 'viewability').toRight(),
  VIEWS: new Column('VIEWS', 'views').toRight(),
  VIEWS_BOOKED: new Column('VIEWS BOOKED', 'viewsBooked').toRight(),
  VTR: new Column('VTR', 'vtr').toRight(),
  VISITS: new Column('VISITS', 'visits').toRight(),
  VISITS_BOOKED: new Column('VISITS BOOKED', 'visitsBooked').toRight(),
  LEADS: new Column('LEADS', 'leads').toRight(),
  LEADS_BOOKED: new Column('LEADS BOOKED', 'leadsBooked').toRight(),
  ENGAGEMENTS: new Column('ENGAGEMENTS', 'engagements').toRight(),
  ENGAGEMENTS_PERCENT: new Column('ENGAGEMENTS %', 'engagementsPercent'),
  ENGAGEMENTS_BOOKED: new Column('ENGAGEMENTS BOOKED', 'engagementsBooked').toRight(),
  SESSIONS: new Column('SESSIONS', 'sessions'),
  NET_REVENUE_EUR: new Column('GROSS REVENUE EUR', 'netRevenue').toRight().setCurrency('EUR'),
  NET_REVENUE_USD: new Column('GROSS REVENUE USD', 'netRevenueUsd').toRight().setCurrency('USD'),
  NET_CPM_EUR: new Column('GROSS UNIT PRICE EUR', 'netECPM').toRight().setCurrency('EUR'),
  NET_CPM_USD: new Column('GROSS UNIT PRICE USD', 'netECPMUsd').toRight().setCurrency('USD'),
  PRODUCT_FAMILY: new Column('PRODUCT FAMILY', 'productFamily'),
});

export const SECONDARY_COLUMNS = Object.freeze({
  BROWSER: new Column('BROWSER', 'Browser'),
  COUNTRY: new Column('COUNTRY', 'Country'),
  DATE: new Column('DATE', 'Date'),
  DEVICE: new Column('DEVICE', 'Device'),
  PLATFORM: new Column('PLATFORM', 'platform'),
  PRODUCT: new Column('PRODUCT', 'product'),
  CAMPAIGN: new Column('CAMPAIGN', 'campaign'),
});
export const TOTALS_COLUMNS = Object.freeze([
  new Column('Impressions', 'impressions'),
  new Column('Clicks', 'clicks'),
  new Column('Net Revenue', 'netRevenue'),
]);

export const COLOR_OPTIONS = {
  DEFAULT: null,
  GREEN: 'green',
  RED: 'red',
  AMBER: 'amber',
};

export const rowClassRules = {
  'product-starting': params => {
    return params.data?.color === COLOR_OPTIONS.GREEN || params.node.aggData?.color === COLOR_OPTIONS.GREEN;
  },
  'product-ending': params => {
    return params.data?.color === COLOR_OPTIONS.RED || params.node.aggData?.color === COLOR_OPTIONS.RED;
  },
  'product-tomorrow': params => {
    return params.data?.color === COLOR_OPTIONS.AMBER || params.node.aggData?.color === COLOR_OPTIONS.AMBER;
  },
};

const CONFIG_V2 = {
  COMMON_ID_VIEW: 'third-party-analytics-views',
  dateList: defaultDateList,
  rowClassRules,
  defaultDate: { startDate: addDays(TODAY, -1), endDate: addDays(TODAY, -1) },
  limitMaxDate: addDays(TODAY, -1),
  CUSTOM_OPTION: 'Custom',
  breakdown: {
    name: 'By structure',
    value: BREAKDOWN_VALUES.structure,
    items: [
      {
        name: 'Product',
        value: BREAKDOWN_VALUES.product,
        items: {
          name: 'By Dimension',
          value: 'byProduct',
          items: [
            { name: 'Country', value: BREAKDOWN_VALUES.country },
            { name: 'Date', value: BREAKDOWN_VALUES.date },
            { name: 'Device', value: BREAKDOWN_VALUES.device },
            { name: 'Platform', value: BREAKDOWN_VALUES.platform },
          ],
        },
      },
      {
        name: 'Campaign',
        value: BREAKDOWN_VALUES.campaign,
        items: {
          name: 'By Dimension',
          value: 'byCampaign',
          items: [
            { name: 'Country', value: BREAKDOWN_VALUES.country },
            { name: 'Date', value: BREAKDOWN_VALUES.date },
            { name: 'Device', value: BREAKDOWN_VALUES.device },
            { name: 'Platform', value: BREAKDOWN_VALUES.platform },
            { name: 'Product', value: BREAKDOWN_VALUES.product },
          ],
        },
      },
      {
        name: 'Platform',
        value: BREAKDOWN_VALUES.platform,
        items: {
          name: 'By Dimension',
          value: 'byPlatform',
          items: [
            { name: 'Country', value: BREAKDOWN_VALUES.country },
            { name: 'Date', value: BREAKDOWN_VALUES.date },
            { name: 'Device', value: BREAKDOWN_VALUES.device },
            { name: 'Campaign', value: BREAKDOWN_VALUES.campaign },
            { name: 'Product', value: BREAKDOWN_VALUES.product },
          ],
        },
      },
    ],
  },
  defaultColDef: {
    sortable: true,
    unSortIcon: true,
    filter: true,
    resizable: true,
    floatingFilter: true,
    minWidth: 50,
    width: 100,
    cellRenderer: 'AnalyticsCellOrchestrator',
    suppressMenu: true,
  },
  gridOptions: {
    suppressCopyRowsToClipboard: true,
  },
  defaultColumns: [
    COLUMNS.ID,
    COLUMNS.NAME,
    COLUMNS.PRODUCT_NAME,
    COLUMNS.CAMPAIGN_NAME,
    COLUMNS.ADSERVER_NAME,
    COLUMNS.OFFICE,
    COLUMNS.CAMPAIGN_ID,
    COLUMNS.PRODUCT_ID,
    COLUMNS.PLATFORM_NAME,
    COLUMNS.OPPORTUNITY_OWNER,
    COLUMNS.ACCOUNT_MANAGER,
    COLUMNS.DATE_START,
    COLUMNS.DATE_END,
    COLUMNS.PACING,
    COLUMNS.MODEL,
    COLUMNS.BOOKED_UNITS,
    COLUMNS.DAILY,
    COLUMNS.IMPRESSIONS,
    COLUMNS.MEASURABLE_IMPRESSIONS,
    COLUMNS.VIEWS,
    COLUMNS.VTR,
    COLUMNS.VIEWABLE_IMPRESSIONS,
    COLUMNS.VIEWABILITY,
    COLUMNS.CLICKS,
    COLUMNS.CTR,
    COLUMNS.ENGAGEMENTS,
    COLUMNS.ENGAGEMENTS_PERCENT,
    COLUMNS.UU,
    COLUMNS.LEADS,
    COLUMNS.VISITS,
    COLUMNS.SESSIONS,
    COLUMNS.NET_REVENUE_EUR,
    COLUMNS.NET_CPM_EUR,
    COLUMNS.NET_REVENUE_USD,
    COLUMNS.NET_CPM_USD,
  ],
  columnTypes: {
    dateColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'greaterThanOrEqual', 'lessThanOrEqual', 'inRange'],
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const cellDate = new Date(cellValue);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          return cellDate > filterLocalDateAtMidnight ? 1 : 0;
        },
      },
    },
    textColumn: {
      filter: 'agTextColumnFilter',
      filterValueGetter: textFilterValueGetter,
      filterParams: {
        textMatcher: ({ node, filterOption, value, filterText }) => {
          if (node.level !== 0) {
            return true;
          }
          if (filterText == null) {
            return false;
          }
          switch (filterOption) {
            case 'contains':
              return value.indexOf(filterText) >= 0;
            case 'notContains':
              return value.indexOf(filterText) < 0;
            case 'equals':
              return value === filterText;
            case 'notEqual':
              return value !== filterText;
            case 'startsWith':
              return value.indexOf(filterText) === 0;
            case 'endsWith':
              // eslint-disable-next-line no-case-declarations
              const index = value.lastIndexOf(filterText);
              return index >= 0 && index === value.length - filterText.length;
            default:
              // should never happen
              // console.warn(`invalid filter type ${filter}`);
              return false;
          }
        },
      },
    },
    numberColumn: {
      filter: 'agNumberColumnFilter',
      comparator: (a, b) => {
        const safeA = a === 'N/A' ? 0 : a;
        const safeB = b === 'N/A' ? 0 : b;
        return safeA - safeB;
      },
      filterParams: {
        numberParser: text => (text == null ? null : parseFloat(text.replace(',', '.'))),
      },
    },
    currencyColumn: {
      filter: 'agNumberColumnFilter',
      comparator: (a, b) => (a || 0) - (b || 0),
      filterValueGetter: params => {
        const colId = params.column.colId;
        const value = params.data[colId] || null;

        return value?.value;
      },
      filterParams: {
        numberParser: text => (text == null ? null : parseFloat(text.replace(',', '.'))),
      },
    },
  },
  totalColumns: TOTALS_COLUMNS,
  exportParams: {
    sheetName: 'Direct Analytics',
    processCellCallback: params => {
      if (!params.value) return '-';
      return params.value;
    },
  },
};

export const agGridColumns = CONFIG_V2.defaultColumns.map(transformColumnsForAgGrid);
export const agGridSecondaryColumns = Object.values(SECONDARY_COLUMNS).map(transformColumnsForAgGrid);
export default CONFIG_V2;
